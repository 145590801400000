@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/br-firma");

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}



body {
  margin: 0;
  font-family: "BR Firma", sans-serif;
  font-optical-sizing: auto;
}

.ant-modal-content {
  font-family: "BR Firma", sans-serif;
  font-optical-sizing: auto;
}
.ant-drawer-content-wrapper {
  font-family: "BR Firma", sans-serif;
  font-optical-sizing: auto;
}

